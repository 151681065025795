import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'CYF-USDT.E LP',
    lpAddresses: {
      43114: '0x8dcb95A8CD13A734A470A1808a2472bD6B3A7A56',
    },
    tokenSymbol: 'CYF',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b',
    },
    quoteTokenSymbol: QuoteToken.USDT_E,
    quoteTokenAdresses: contracts.usdt_e,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'CYF-AVAX LP',
    lpAddresses: {
      43114: '0x3437Bf22e261c79328e3B91a1F299e057fA12Cb6',
    },
    tokenSymbol: 'CYF',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 14,
    risk: 5,
    lpSymbol: 'CYF-USDC.E LP',
    lpAddresses: {
      43114: '0x0e79b2f73461d682174b00e676b68237ef8583f7',
    },
    tokenSymbol: 'CYF',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc_e,
  },
  {
    pid: 17,
    risk: 5,
    lpSymbol: 'CYF-xUSD LP',
    lpAddresses: {
      43114: '0x60ef780FB54373088b93db6600BbBAA90Eb14243',
    },
    tokenSymbol: 'CYF',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b',
    },
    quoteTokenSymbol: QuoteToken.XUSD,
    quoteTokenAdresses: contracts.xusd,
  },
  {
    pid: 21,
    risk: 5,
    otherExchange: 'TraderJoe',
    lpSymbol: 'CYF-GB LP',
    lpAddresses: {
      43114: '0xc16eb20628cce00ed38ece8e6145a9871b5c028e',
    },
    tokenSymbol: 'GB',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  {
    pid: 2,
    risk: 5,
    lpSymbol: 'USDT.E-AVAX LP',
    lpAddresses: {
      43114: '0xe28984e1ee8d431346d32bec9ec800efb643eef4',
    },
    tokenSymbol: 'AVAX',
    tokenAddresses: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    },
    quoteTokenSymbol: QuoteToken.USDT_E,
    quoteTokenAdresses: contracts.usdt_e,
  },
  
  {
    pid: 3,
    risk: 5, //
    lpSymbol: 'ETH.E-AVAX LP',
    lpAddresses: {
      43114: '0x7c05d54fc5cb6e4ad87c6f5db3b807c94bb89c52',
    },
    tokenSymbol: 'WETH.E',
    tokenAddresses: {
      43114: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },

    
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'AVAX-DAI.E LP',
    lpAddresses: {
      43114: '0xba09679ab223c6bdaf44d45ba2d7279959289ab0',
    },
    tokenSymbol: 'DAI.E',
    tokenAddresses: {
      43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    },
    quoteTokenSymbol: QuoteToken.DAI_e,
    quoteTokenAdresses: contracts.dai_e,
  },

      
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'WBTC.E-AVAX LP',
    lpAddresses: {
      43114: '0x5764b8d8039c6e32f1e5d8de8da05ddf974ef5d3',
    },
    tokenSymbol: 'WAVAX',
    tokenAddresses: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },

  {
    pid: 6,
    risk: 5,
    lpSymbol: 'PNG-AVAX LP',
    lpAddresses: {
      43114: '0xd7538cabbf8605bde1f4901b47b8d42c61de0367',
    },
    tokenSymbol: 'PNG',
    tokenAddresses: {
      43114: '0x60781c2586d68229fde47564546784ab3faca982',
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },


  // Pools

  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'CYF',
    lpAddresses: {
      43114: '0x8dcb95A8CD13A734A470A1808a2472bD6B3A7A56'
    },
    tokenSymbol: 'CYF',
    tokenAddresses: {
      43114: '0x411491859864797792308723Fc417f11BbA18D1b'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },

  {
    pid: 8,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDT.E',
    lpAddresses: {
      43114: '0xe28984e1ee8d431346d32bec9ec800efb643eef4'
    },
    tokenSymbol: 'USDT.E',
    tokenAddresses: {
      43114: '0xc7198437980c041c805a1edcba50c1ce5db95118'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },



  {
    pid: 9,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WAVAX',
    lpAddresses: {
      43114: '0xe28984e1ee8d431346d32bec9ec800efb643eef4'
    },
    tokenSymbol: 'WAVAX',
    tokenAddresses: {
      43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },


  {
    pid: 10,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'PNG',
    lpAddresses: {
      43114: '0x1ffb6ffc629f5d820dcf578409c2d26a2998a140'
    },
    tokenSymbol: 'PNG',
    tokenAddresses: {
      43114: '0x60781c2586d68229fde47564546784ab3faca982'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },


  {
    pid: 11,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH.E',
    lpAddresses: {
      43114: '0xd3c14566bba068ec07f8cc768ec65614f71d49dc'
    },
    tokenSymbol: 'WETH.E',
    tokenAddresses: {
      43114: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc_e,
  },

  {
    pid: 12,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WBTC.E',
    lpAddresses: {
      43114: '0x2d8d5a8d1f82b28a6f50674802e39e7bdad466a4'
    },
    tokenSymbol: 'WBTC.E',
    tokenAddresses: {
      43114: '0x50b7545627a5162f82a992c33b87adc75187b218'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc_e,
  },

  {
    pid: 13,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI.E',
    lpAddresses: {
      43114: '0x7AbaB5474385918820dfBC7f35712084a91B583a'
    },
    tokenSymbol: 'DAI.E',
    tokenAddresses: {
      43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },
  {
    pid: 15,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC.E',
    lpAddresses: {
      43114: '0x221caccd55f16b5176e14c0e9dbaf9c6807c83c9'
    },
    tokenSymbol: 'USDC.E',
    tokenAddresses: {
      43114: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc_e,
  },

  {
    pid: 16,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'TUNDRA',
    lpAddresses: {
      43114: '0x317598200315f454d1b5e5cccf07c2e2c6aee172'
    },
    tokenSymbol: 'TUNDRA',
    tokenAddresses: {
      43114: '0x21c5402c3b7d40c89cc472c9df5dd7e51bbab1b1'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdt_e,
  },

  {
    pid: 18,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'xUSD',
    lpAddresses: {
      43114: '0xed84fedb633d0523de53d46c8c99cbae1f89d3b0'
    },
    tokenSymbol: 'xUSD',
    tokenAddresses: {
      43114: '0x3509f19581afedeff07c53592bc0ca84e4855475'
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },
    {
    pid: 19,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'GDL',
    lpAddresses: {
      43114: '0xc5ab0c94bc88b98f55f4e21c1474f67ab2329cfd'
    },
    tokenSymbol: 'GDL',
    tokenAddresses: {
      43114: '0xd606199557c8ab6f4cc70bd03facc96ca576f142'
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },
  {
    pid: 20,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'YAK',
    lpAddresses: {
      43114: '0xd2f01cd87a43962fd93c21e07c1a420714cc94c9'
    },
    tokenSymbol: 'YAK',
    tokenAddresses: {
      43114: '0x59414b3089ce2af0010e7523dea7e2b35d776ec7'
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },
]

export default farms
