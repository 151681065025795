import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
      <path d="M0,335v132c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V335H0z" />
      <path d="452,0 452,30 60,30 60,0 30,0 30,305 60,305 60,60 452,60 452,305 482,305 482,0" />
      <path d="M160.999,217V98h-30v119H91v30h10v58h15.762l63.414-63.414c6.151-6.151,13.188-11.097,20.823-14.727V217H160.999z" />
      <path d="M400.086,167.34l-53.033-53.033L325.84,135.52l-21.214-21.213l21.213-21.213L304.627,71.88l-63.64,63.64l21.213,21.213    l21.213-21.213l21.214,21.213l-21.214,21.214l21.213,21.213l-53.984,53.984c-16.354-6.85-35.961-3.637-49.254,9.656L159.189,305    h117.813c0.791-3.364,1.208-6.84,1.208-10.381c0-7.551-1.85-14.817-5.331-21.285l52.962-52.962l63.639,63.64l10.606-10.607    C429.328,244.163,429.328,196.582,400.086,167.34z" />
    </Svg>
  );
};

export default Icon;
