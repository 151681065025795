export default {
  cake: {
    43114: '0x411491859864797792308723Fc417f11BbA18D1b'
  },
  masterChef: {
    43114: '0xaB0141F81b3129f03996D0679b81C07F6A24c435'
  },
  weth: {
    43114: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    43114: '0x98e2060F672FD1656a07bc12D7253b5e41bF3876'
  },
  wavax: {
    43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
  },
  sushi: {
    43114: '0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc'
  },
  png: {
    43114: '0x60781C2586D68229fde47564546784ab3fACA982'
  },
  wbtc: {
    43114: '0x408d4cd0adb7cebd1f1a1c33a0ba2098e1295bab'
  },
  link: {
    43114: '0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651'
  },
  usdt_e: {
    43114: '0xc7198437980c041c805a1edcba50c1ce5db95118'
  },
  eth_e: {
    43114: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
  },
  sushi_e: {
    43114: '0x37b608519f91f70f2eeb0e5ed9af4061722e4f76'
  },
  dai_e: {
    43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70'
  },
  wbtc_e: {
    43114: '0x50b7545627a5162f82a992c33b87adc75187b218'
  },
  usdc_e: {
    43114: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664'
  },
  link_e: {
    43114: '0x5947bb275c521040051d82396192181b413227a3'
  },
  xusd: {
    43114: '0x3509f19581afedeff07c53592bc0ca84e4855475'
  },
} 
