import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 426.667 426.667" {...props}>
      <path
        d="M341.333,53.333L256,138.667h64V288c0,23.573-19.093,42.667-42.667,42.667c-23.573,0-42.667-19.093-42.667-42.667V138.667    c0-47.04-38.293-85.333-85.333-85.333S64,91.627,64,138.667V288H0l85.333,85.333L170.667,288h-64V138.667    c0-23.573,19.093-42.667,42.667-42.667S192,115.093,192,138.667V288c0,47.04,38.293,85.333,85.333,85.333    c47.04,0,85.333-38.293,85.333-85.333V138.667h64L341.333,53.333z"
      />
    </Svg>
  );
};

export default Icon;
