import { createGlobalStyle, keyframes } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const RainbowLight = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
  }
  body {
    background: linear-gradient(-45deg, #3f51b1, #5a55ae, #7b5fac, #8f6aae, #a86aa4, #cc6b8e, #f18271, #f3a469, #f7c978);
    background-size:  400% 400%;
    animation: ${RainbowLight} 15s ease infinite;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-width: 100vh;
    min-width: -webkit-fill-available;
}
`


export default GlobalStyle


// background-color: ${({ theme }) => theme.colors.background};
// background-size: auto;
// background-attachment: cover;
// background-repeat: repeat