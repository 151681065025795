import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 511.998 511.998" {...props}>
      <path d="m20.294 361.106 1.402-.528c38.894-14.66 75.631-28.508 113.806-28.508 17.947 0 35.911 6.813 54.93 14.025 20.709 7.854 42.123 15.975 65.567 15.975s44.858-8.121 65.567-15.975c19.019-7.213 36.982-14.025 54.93-14.025 36.969 0 74.351 13.874 110.501 27.291l4.787 1.775c1.718.636 3.478.938 5.208.938 6.096 0 11.827-3.743 14.068-9.794 2.877-7.769-1.088-16.399-8.856-19.276l-4.769-1.769c-36.834-13.67-78.583-29.165-120.939-29.165-1.172 0-2.336.035-3.498.075l-.001-221.147c0-33.084-26.916-60-60-60s-60 26.916-60 60c0 8.284 6.716 15 15 15s15-6.716 15-15c0-16.542 13.458-30 30-30s30 13.458 30 30l.001 45.001h-173.998l-.001-45.001c0-33.084-26.916-60-60-60s-60 26.916-60 60c0 8.284 6.716 15 15 15s15-6.716 15-15c0-16.542 13.458-30 30-30s30 13.458 30 30l.001 221.146c-1.162-.04-2.326-.075-3.498-.075-43.641 0-84.689 15.473-124.387 30.436l-1.401.528c-7.752 2.922-11.668 11.574-8.746 19.326 2.922 7.751 11.569 11.668 19.326 8.747zm148.706-145.108h173.998v29.999h-173.998zm173.998-60v30h-173.998v-30zm-173.998 119.999h173.998v31.032c-10.983 3.022-21.616 7.051-32.069 11.015-19.019 7.213-36.982 14.025-54.93 14.025s-35.911-6.813-54.93-14.025c-10.453-3.964-21.087-7.993-32.069-11.015z" />
      <path d="m419.339 461.98-.358-.136c-21.79-8.302-54.719-20.847-83.981-20.847-16.094 0-30.715 5.586-44.854 10.988-12.131 4.634-23.588 9.012-34.146 9.012s-22.016-4.377-34.146-9.012c-14.14-5.402-28.76-10.988-44.854-10.988-25.121 0-41.314 5.75-68.144 15.276-4.804 1.706-10.019 3.558-15.77 5.551-7.827 2.714-11.973 11.259-9.26 19.086 2.15 6.201 7.959 10.091 14.172 10.091 1.63 0 3.288-.268 4.914-.831 5.828-2.021 11.113-3.897 15.982-5.626 26.195-9.302 38.151-13.547 58.104-13.547 10.559 0 22.017 4.378 34.147 9.013 14.139 5.401 28.759 10.987 44.853 10.987s30.715-5.586 44.854-10.988c12.131-4.634 23.588-9.012 34.146-9.012 23.741 0 53.566 11.362 73.302 18.881l.358.137c7.741 2.946 16.407-.937 19.357-8.678 2.951-7.741-.935-16.408-8.676-19.357z" />
      <path d="m502.204 395.431-4.755-1.764c-36.839-13.673-78.592-29.17-120.953-29.17-23.444 0-44.858 8.121-65.567 15.975-19.019 7.213-36.982 14.025-54.93 14.025s-35.911-6.813-54.93-14.025c-20.709-7.854-42.123-15.975-65.567-15.975-43.641 0-84.689 15.473-124.387 30.436l-1.401.528c-7.752 2.922-11.668 11.574-8.746 19.326 2.922 7.751 11.569 11.667 19.326 8.746l1.402-.528c38.894-14.66 75.631-28.508 113.806-28.508 17.947 0 35.911 6.813 54.93 14.025 20.709 7.854 42.123 15.975 65.567 15.975s44.858-8.121 65.567-15.975c19.019-7.213 36.982-14.025 54.93-14.025 36.974 0 74.359 13.876 110.514 27.295l4.774 1.771c1.718.636 3.478.938 5.208.938 6.096 0 11.827-3.743 14.068-9.794 2.878-7.768-1.087-16.398-8.856-19.276z" />
    </Svg>
  );
};

export default Icon;
