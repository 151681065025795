import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade (Pangolin)',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.pangolin.exchange/#/swap?outputCurrency=0x411491859864797792308723Fc417f11BbA18D1b',
      },
      {
        label: 'Liquidity',
        href: 'https://app.pangolin.exchange/#/add/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/0x411491859864797792308723Fc417f11BbA18D1b',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'BYOF',
    icon: 'ByofIcon',
    href: '/byof',
  },
  {
    label: 'Competition',
    icon: 'CompetitionIcon',
    href: '/competition',
  },
  // {
  //   label: 'Community Pools',
  //   icon: 'GroupsIcon',
  //   href: '/community',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Pangolin',
        href: 'https://info.pangolin.exchange/#/pair/0x8dcb95a8cd13a734a470a1808a2472bd6b3a7a56',
      },
      {
        label: 'Chartex',
        href: 'https://chartex.pro/?symbol=AVAX_PANGOLIN%3ACYF%2FUSDTe.0x8dcb95A8CD13A734A470A1808a2472bD6B3A7A56&interval=5&theme=dark',
      },
      {
        label: 'Nomics',
        href: 'https://nomics.com/assets/CYF2-Crypto-Yield-Focus?utm_source=twitter&utm_medium=bot&utm_campaign=new_asset&utm_content=cyf2&d=1630870832',
      },
//      {
//        label: 'PooCoin',
//        href: 'https://polygon.poocoin.app/tokens/0x782eb3304f8b9add877f13a5ca321f72c4aa9804',
//      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Github',
      //   href: 'https://github.com/polydaedalus',
      // },
      {
        label: 'Docs',
        href: 'https://docs.cryptoyieldfocus.com/',
      },
      {
        label: 'Reddit',
        href: 'https://www.reddit.com/r/CryptoMoonShots/comments/pghyg0/cryptoyieldfocus_cyffairlaunch_soon1000_gemhuge/?utm_medium=android_app&utm_source=share',
      },
      {
        label: 'Rugdoc',
        href: 'https://rugdoc.io/project/cryptoyieldfocus/',
      },
      {
        label: 'Jago',
        href: 'https://jagosafer.io/cryptoyieldfocus',
      },
      {
        label: 'Vfat.tools',
        href: 'https://vfat.tools/avax/cryptoyieldfocus/',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/3vP4Y4Bb7d',
      },
      {
        label: 'DefiLlama',
        href: 'https://defillama.com/protocol/cryptoyieldfocus',
      }
    ],
  },
]

export default config
