import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 448.262 448.262" {...props}>
      <path d="M221.2,164.587c4.217,1.654,8.354-2.394,6.956-6.701c-9.594-29.565-44.331-40.594-76.623-14.364    c-16.986,13.799-23.369,16.731-23.53,16.805c11.198,11.196,23.991,48.766,51.91,52.921c30.755,5.576,57.975-24.682,35.705-38.528    c-14.646-9.105-31.89-14.394-50.374-14.394C184.622,155.958,203.967,157.831,221.2,164.587z" />
      <path d="M360.464,143.52c-55.742-45.278-91.291,26.126-69.194,19.048c15.694-5.027,32.851-6.122,50.061-2.243    c-29.159,0-56.038,13.363-73.959,35.114c1.472-34.182,3.698-55.578,6.279-71.494c14.895-0.512,34.747-12.54,45.649-33.587    c18.795-33.636,10.079-68.124,7.274-89.886c-14.686,14.301-78.839-2.456-104.271,41.124c-11.234,19.251-7.969,42.677,3.849,59.82    c3.741,5.427,12.686,2.823,12.941-3.763c1.11-28.65,15.369-50.296,41.951-62.285c-11.928,15.455-19.76,32.758-23.806,50.642    c-6.289,27.859-12.858,46.134-15.27,134.929c-0.245,9.005,7.05,16.459,16.058,16.346c6.521-0.082,12.107-4.22,14.615-10.24    c3.814-9.156,10.022-18.632,20.124-27.314c9.6,10.611,24.208,16.257,39.323,13.516c27.8-4.137,40.774-41.789,51.909-52.923    C383.834,160.252,377.45,157.32,360.464,143.52z" />
      <path d="M442.406,254.158c-17.426-11.823-36.392-3.398-45.102,1.631l-109.304,80l-64,0.014l-16-16h58.776    c17.677,0,37.474-11.514,37.474-32.007c0-21.757-19.797-32.007-37.474-32.007h-22.026c-16.362,0-35.483,1.459-48.965-5.758    c-12.809-6.518-27.559-10.227-43.304-10.227c-26.439,0-50.102,10.54-66.317,27.162L0,351.789l96,96l32-48h151.216    c16.127,0,31.694-5.916,43.75-16.626L443.5,276.088C450.201,270.136,449.823,259.191,442.406,254.158z" />
    </Svg>
  );
};

export default Icon;
